const body = $(document.body);
const button_show = $('.btn--show-fullscreen');
const button_hide = $('.btn--hide-fullscreen');

body.on('fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange', (event) => {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
        body.addClass('body--fullscreen');
        $('.page__content').addClass('page__content--fullscreen')
        button_hide.removeClass('hide');
    } else {
        body.removeClass('body--fullscreen');
        $('.page__content').removeClass('page__content--fullscreen')
        button_hide.addClass('hide');
    }
})

button_show.on('click', (event) => {
    if (document.body.requestFullscreen) {
        document.body.requestFullscreen();
    } else if (document.body.mozRequestFullScreen) {
        document.body.mozRequestFullScreen();
    } else if (document.body.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
        document.body.msRequestFullscreen();
    }

    $('.graph__navigation').each((index, element) => {
        if ($(element).height() < 100) {
            $('.graph__navigation').addClass('graph__navigation--row');
        }
    });
})

button_hide.on('click', (event) => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
})
